import { IFiliation } from "./filiation.model";
import { IValeur } from "../administration/valeur.model";

export interface IDemandeur {
    id?: any;
    nom?: string;
    prenom?: string;
    dateNaissance?: Date;
    lieuNaissance?: string;
    lieuResidence?: string;
    numeroActeNaissance?: string;
    dateActeNaissance?: Date;
    lieuEtablissementActe?: string;
    dossier?: string;
    sexe?: IValeur;
    situationMatrimoniale?: IValeur;
    professionLibelle?: string;
    nationalite?: IValeur;
    paysNaissance?: IValeur;
    nomJeuneFille?: string;
    statut?: IValeur;
    dateResidence?: string;
    typePieceIdentite?: IValeur;
    referencePieceIdentite?: string;
    telephone?: string;
    email?: string;
    pere?: IDemandeur;
    mere?: IDemandeur;
    conjoint?: IDemandeur;
    dateMariage?: Date;
    nomParent?: string;
    prenomParent?: string;
}

export const defaultValue: Readonly<IDemandeur> = {
    dossier: null,
}