import { IValeur } from "../administration/valeur.model";
import { string } from "prop-types";

export interface ITypePieceModeObtention {
    id?:any;
    typePiece?:IValeur;
    modeObtention?:IValeur;
    
}

export const defaultValue: Readonly<ITypePieceModeObtention> = {
   
}