import { string } from "prop-types";

export interface ILocalite {
    id?:any;
    libelle?:any;
    deleted?:boolean;
    typeLocalite?:string;
    localiteParentId?:any;
    juridiction?:any;
   
    
}

export const defaultValue: Readonly<ILocalite> = {
   
}