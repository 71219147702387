import { REQUEST, FAILURE, SUCCESS } from "../../../shared/reducers/action-type.util";
import axios from "axios";
import { ICrudGetAllAction, ICrudGetAction ,ICrudPutAction} from "../../../shared/reducers/redux-action.type";
import { IBullettin, defaultValue } from "../../../shared/model/certificat-nationalite-service/bullettin.model";
import { ILigneCasier } from "src/views/shared/model/certificat-nationalite-service/ligne-casier.model";

import { API_SERVICE_URL } from "../../../config/api-service-url";

export const ACTION_TYPES = {
  FETCH_BULLETTIN_LIST: 'bullettin/FETCH_BULLETTIN_LIST',
  FETCH_BULLETTIN: 'bullettin/FETCH_BULLETTIN',
  CREATE_BULLETTIN: 'bullettin/CREATE_BULLETTIN',
  UPDATE_BULLETTIN: 'bullettin/UPDATE_BULLETTIN',
  DELETE_BULLETTIN: 'bullettin/DELETE_BULLETTIN',
  RESET: 'bullettin/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IBullettin>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type BullettinState = Readonly<typeof initialState>;

// Reducer

export default (state: BullettinState = initialState, action: any): BullettinState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BULLETTIN_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BULLETTIN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_BULLETTIN):
    case REQUEST(ACTION_TYPES.UPDATE_BULLETTIN):
    case REQUEST(ACTION_TYPES.DELETE_BULLETTIN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BULLETTIN_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BULLETTIN):
    case FAILURE(ACTION_TYPES.CREATE_BULLETTIN):
    case FAILURE(ACTION_TYPES.UPDATE_BULLETTIN):
    case FAILURE(ACTION_TYPES.DELETE_BULLETTIN):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_BULLETTIN_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_BULLETTIN):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_BULLETTIN):
    case SUCCESS(ACTION_TYPES.UPDATE_BULLETTIN):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_BULLETTIN):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};


const apiUrl = `${API_SERVICE_URL.EMPTY}api/demandes`;
const apiUrl2 = `${API_SERVICE_URL.EMPTY}api/certificats`;
const apiUrl3 = `${API_SERVICE_URL.EMPTY}api/ligne_casiers`;

// Actions

export const getEntities: ICrudGetAllAction<IBullettin> = () => {
  const requestUrl = `${apiUrl}`;
  return {
    type: ACTION_TYPES.FETCH_BULLETTIN_LIST,
    payload: axios.get(requestUrl)
  };
};

export const getEntity: ICrudGetAction<IBullettin> = name => {
  const requestUrl = `${apiUrl}/${name}`;
  return {
    type: ACTION_TYPES.FETCH_BULLETTIN,
    payload: axios.get<IBullettin>(requestUrl)
  };
};



export const getDecisionPersonne = (id) => {
  const requestUrl = `${apiUrl}/${id}/list_by_personne/decisions`;
  return {
    type: ACTION_TYPES.FETCH_BULLETTIN_LIST,
    payload: axios.post(requestUrl)
  };
}


export const createEntity: ICrudPutAction<IBullettin> = entity => {
  const result = {
    type: ACTION_TYPES.CREATE_BULLETTIN,
    payload: axios.post(apiUrl2, entity)
  };
  //dispatch(getEntities());
  return result;
};

export const createLigneCasier: ICrudPutAction<IBullettin> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BULLETTIN,
    payload: axios.post(apiUrl3, entity)
  });
  return result;
};


export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getBulletinByDemande = ( demandeId) => {
  const requestUrl = `${apiUrl2}/ligne-casiers/${demandeId}`;
  return {
    type: ACTION_TYPES.FETCH_BULLETTIN,
    payload: axios.get(requestUrl)
  };
};