import { IDemandeur } from "../demandeur-service/demandeur.model";
import { IValeur } from "../administration/valeur.model";
import { IDocument } from "../demandeur-service/Document.model";

export interface IDemande {
    id?: any;
    objet?: string;
    numero?: string;
    dossier?: string;
    nombre?: number;
    enLigne?: boolean;
    recepDownloaded?: boolean;
    statut?: string;
    descriptionRejet?:string;
    demandeur?: IDemandeur;
    motifRejet?: IValeur;
    modifiedCount?: number;
    dateRejet?:string;
    modifiedExpire?:boolean;
    modeObtention?: IValeur;
    lieuRetrait?: IValeur;
    documentsReferences?: IDocument[];
    created_date?: string;
    documents?: any;
}

export const defaultValue: Readonly<IDemande> = {
    nombre: 1,
    statut: "NON_PAYE",
    objet: "demande de certificat de nationalité",
    enLigne: true,
    recepDownloaded: false,
    modifiedCount: 0,
    modifiedExpire: false,
}