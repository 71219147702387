import { REQUEST, FAILURE, SUCCESS } from "../../../shared/reducers/action-type.util";
import axios from "axios";
import { ICrudGetAllAction, ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from "../../../shared/reducers/redux-action.type";
import { IDemandeur, defaultValue } from "../../../shared/model/demandeur-service/demandeur.model";
import { cleanEntity } from "src/views/utils/entity-utils";

export const ACTION_TYPES = {
  FETCH_DEMANDEUR_LIST: 'demandeur/FETCH_DEMANDEUR_LIST',
  FETCH_DEMANDEUR: 'demandeur/FETCH_DEMANDEUR',
  CREATE_DEMANDEUR: 'demandeur/CREATE_DEMANDEUR',
  UPDATE_DEMANDEUR: 'demandeur/UPDATE_DEMANDEUR',
  DELETE_DEMANDEUR: 'demandeur/DELETE_DEMANDEUR',
  RESET: 'demandeur/RESET'
};

  const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IDemandeur>,
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false
  };

  export type DemandeurState = Readonly<typeof initialState>;

// Reducer

export default (state: DemandeurState = initialState, action: any): DemandeurState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DEMANDEUR_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DEMANDEUR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_DEMANDEUR):
    case REQUEST(ACTION_TYPES.UPDATE_DEMANDEUR):
    case REQUEST(ACTION_TYPES.DELETE_DEMANDEUR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_DEMANDEUR_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DEMANDEUR):
    case FAILURE(ACTION_TYPES.CREATE_DEMANDEUR):
    case FAILURE(ACTION_TYPES.UPDATE_DEMANDEUR):
    case FAILURE(ACTION_TYPES.DELETE_DEMANDEUR):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEMANDEUR_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEMANDEUR):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_DEMANDEUR):
    case SUCCESS(ACTION_TYPES.UPDATE_DEMANDEUR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_DEMANDEUR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};


const apiUrl = `api/demandeurs`;
const apiEmailUrl = `api`;


// Actions

export const getEntities: ICrudGetAllAction<IDemandeur> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_DEMANDEUR_LIST,
    payload: axios.get(requestUrl)
  };
};

export const getList: ICrudGetAllAction<IDemandeur> = () => {
  const requestUrl = `${apiUrl}`;
  return {
    type: ACTION_TYPES.FETCH_DEMANDEUR_LIST,
    payload: axios.get(requestUrl)
  };
};

export const getEntity: ICrudGetAction<IDemandeur> = libelle => {
  const requestUrl = `${apiUrl}/${libelle}`;
  return {
    type: ACTION_TYPES.FETCH_DEMANDEUR,
    payload: axios.get<IDemandeur>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IDemandeur> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DEMANDEUR,
    payload: axios.post(apiUrl, entity)
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDemandeur> = entity => async dispatch =>  {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DEMANDEUR,
    payload: axios.put(apiUrl, entity)
  });
 dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDemandeur> = libelle => async dispatch => {
  const requestUrl = `${apiUrl}/${libelle}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DEMANDEUR,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};


export const cotactFormEmail =(entity) => {
  const requestUrl = `${apiUrl}/send-email`;
  return{
    payload: axios.post(requestUrl,cleanEntity(entity))
  }
  
};



export const reset = () => ({
  type: ACTION_TYPES.RESET
});