import { createStore, compose, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import thunkMiddleware from "redux-thunk";
import reducer, { IRootState } from "./views/shared/reducers";
import loggerMiddleware from "./views/config/logger-middleware";
import errorMiddleware from "./views/config/error-middleware";
import notificationMiddleware from "./views/config/notification-middleware";

const defaultMiddlewares = [
  thunkMiddleware,
  errorMiddleware,
  notificationMiddleware,
  promiseMiddleware,
  loggerMiddleware,
];
const composedMiddlewares = (middlewares) =>
  compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const store = (initialState?: IRootState, middlewares = []) => {
  return createStore(
    reducer,
    initialState,
    composedMiddlewares(middlewares)
  );
};
export default store;
