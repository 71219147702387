import { REQUEST, FAILURE, SUCCESS } from "../../../shared/reducers/action-type.util";
import axios from "axios";
import { ICrudGetAllAction, ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from "../../../shared/reducers/redux-action.type";
import { IDocument, defaultValue } from "../../../shared/model/demandeur-service/Document.model";

import { API_SERVICE_URL } from "../../../config/api-service-url";
import { cleanEntity } from "src/views/utils/entity-utils";

export const ACTION_TYPES = {
  FETCH_DOCUMENT_LIST: 'document/FETCH_DOCUMENT_LIST',
  FETCH_DOCUMENT: 'document/FETCH_DOCUMENT',
  CREATE_DOCUMENT: 'document/CREATE_DOCUMENT',
  UPDATE_DOCUMENT: 'document/UPDATE_DOCUMENT',
  DELETE_DOCUMENT: 'document/DELETE_DOCUMENT',
  RESET: 'document/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDocument>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type DocumentState = Readonly<typeof initialState>;

// Reducer

export default (state: DocumentState = initialState, action: any): DocumentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_DOCUMENT):
    case REQUEST(ACTION_TYPES.UPDATE_DOCUMENT):
    case REQUEST(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT):
    case FAILURE(ACTION_TYPES.CREATE_DOCUMENT):
    case FAILURE(ACTION_TYPES.UPDATE_DOCUMENT):
    case FAILURE(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_DOCUMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};


const apiUrl = `${API_SERVICE_URL.EMPTY}api/documents`;
const apiUrlDemande = `${API_SERVICE_URL.EMPTY}api/demandes`;

// Actions

export const getEntities: ICrudGetAllAction<IDocument> = () => {
  const requestUrl = `${apiUrl}`;
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
    payload: axios.get(requestUrl)
  };
};


export const getDocumentByDemande  = (demandeId) => {
  const requestUrl = `${apiUrl}/by_demande/${demandeId}`;
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
    payload: axios.get(requestUrl)
  };
};

export const getDocumentDemande  = (demandeId) => {
  const requestUrl = `${apiUrlDemande}/documents/en-ligne/${demandeId}`;
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
    payload: axios.get(requestUrl)
  };
};


export const getEntity: ICrudGetAction<IDocument> = name => {
  const requestUrl = `${apiUrl}/${name}`;

  return {
    type: ACTION_TYPES.FETCH_DOCUMENT,
    payload: axios.get<IDocument>(requestUrl)
  };
};


export const createEntity: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.post(apiUrl, entity)
  });
  dispatch(getEntity(result.action.payload.data.id));
  return result;
};

export const createEntityMultiple: any = entities => async dispatch => {
  const requestUrl = `${apiUrl}/save-multiple`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DOCUMENT,
    payload: axios.put(requestUrl, entities)
  });
  //dispatch(getEntity(result.action.payload.data.id));
  //return result;
};

export const createEntityForStep: ICrudPutAction<IDocument> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntity(result.action.payload.data.numeroDocument));
  return result;
};

export const updateEntityForStep: ICrudPutAction<IDocument> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DOCUMENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntity(result.action.payload.data.numeroDocument));
  return result;
};

export const updateEntity: any = entity => async dispatch =>  {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DOCUMENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
 dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDocument> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DOCUMENT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});