import { REQUEST, FAILURE, SUCCESS } from "../../../shared/reducers/action-type.util";
import axios from "axios";
import { ICrudGetAllAction, ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from "../../../shared/reducers/redux-action.type";
import { IDemande, defaultValue } from "../../../shared/model/certificat-nationalite-service/demande.model";

import { API_SERVICE_URL } from "../../../config/api-service-url";
import { cleanEntity } from "src/views/utils/entity-utils";
import { getEntities } from "./bullettin.reducer";

export const ACTION_TYPES = {
  FETCH_DEMANDE_LIST: 'demande/FETCH_DEMANDE_LIST',
  FETCH_DEMANDE: 'demande/FETCH_DEMANDE',
  CREATE_DEMANDE: 'demande/CREATE_DEMANDE',
  UPDATE_DEMANDE: 'demande/UPDATE_DEMANDE',
  SUIVI_DEMANDE: 'demande/SUIVI_DEMANDE',
  DELETE_DEMANDE: 'demande/DELETE_DEMANDE',
  CHANGE_STATE: 'demande/CHANGE_STATE',
  RESET: 'demande/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDemande>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type DemandeState = Readonly<typeof initialState>;

// Reducer

export default (state: DemandeState = initialState, action: any): DemandeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DEMANDE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DEMANDE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_DEMANDE):
    case REQUEST(ACTION_TYPES.SUIVI_DEMANDE):
    case REQUEST(ACTION_TYPES.UPDATE_DEMANDE):
    case REQUEST(ACTION_TYPES.DELETE_DEMANDE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_DEMANDE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DEMANDE):
    case FAILURE(ACTION_TYPES.CREATE_DEMANDE):
    case FAILURE(ACTION_TYPES.UPDATE_DEMANDE):
    case FAILURE(ACTION_TYPES.SUIVI_DEMANDE):
    case FAILURE(ACTION_TYPES.DELETE_DEMANDE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
       //errorMessage: action.meta.errorMessage
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEMANDE_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEMANDE):
      return {
        ...state,
        loading: false,
        updating: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_DEMANDE):
    case SUCCESS(ACTION_TYPES.UPDATE_DEMANDE):
    case SUCCESS(ACTION_TYPES.SUIVI_DEMANDE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_DEMANDE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.CHANGE_STATE:
      return {
        ...state,
        updating: false,
        updateSuccess: false
      };
    default:
      return state;
  }
};


const apiUrl = `${API_SERVICE_URL.EMPTY}api/demandes`;
const apiEmailUrl = `${API_SERVICE_URL.EMPTY}api`;
// Actions


export const getEntity: ICrudGetAction<IDemande> = name => {
  const requestUrl = `${apiUrl}/${name}`;
  return {
    type: ACTION_TYPES.FETCH_DEMANDE,
    payload: axios.get<IDemande>(requestUrl)
  };
};

export const getEntityEnLigne: ICrudGetAction<IDemande> = name => {
  const requestUrl = `${apiUrl}/en-ligne/${name}`;
  return {
    type: ACTION_TYPES.FETCH_DEMANDE,
    payload: axios.get<IDemande>(requestUrl)
  };
};

export const getDecisionPersonne: ICrudPutAction<IDemande> = (entity) => {
  const requestUrl = `${apiUrl}/${entity.id}/list_by_personne/decisions`;
  return {
    type: ACTION_TYPES.FETCH_DEMANDE_LIST,
    payload: axios.post(requestUrl)
  };
};

export const getSuiviDemande: any = entity => {
  const requestUrl = `${apiUrl}/suivie`;
  const result = {
    type: ACTION_TYPES.SUIVI_DEMANDE,
    payload: axios.post(requestUrl, entity),
    meta: {
      errorMessage: "Les données renseignées ne correspondent pas à une demande !",
      successMessage: "Demande trouvée !"
    }
  };
  return result
}

export const createEntityWithFile: any = entity => {
  const requestUrl = `${apiUrl}/en-ligne`;
  const result = {
    type: ACTION_TYPES.CREATE_DEMANDE,
    payload: axios.post(requestUrl, entity)
  };
  return result;
};


{/*export const createEntity: ICrudPutAction<IDemande> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DEMANDE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};*/}

export const updateEntity: ICrudPutAction<IDemande> = entity => {
  const requestUrl = `${apiUrl}/${entity.id}`;
  const result = {
    type: ACTION_TYPES.UPDATE_DEMANDE,
    payload: axios.patch(requestUrl, entity)
  };
  //dispatch(getEntities());
  return result;
};

export const updateEntityWithFile: any = entity => {
  const requestUrl = `${apiUrl}/update`;
  const result = {
    type: ACTION_TYPES.UPDATE_DEMANDE,
    payload: axios.post(requestUrl, entity)
  };
  //dispatch(getEntities());
  return result;
};


export const deleteEntity: ICrudDeleteAction<IDemande> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DEMANDE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const change = () => ({
  type: ACTION_TYPES.CHANGE_STATE
});

export const createContactEmail = (entity) => {
  const requestUrl = `${apiUrl}/send-mail`;
 
  return {
    payload: axios.post(requestUrl, cleanEntity(entity))//result;

  };
}

export const sendEmail: any = entity => {
  const requestUrl = `${apiEmailUrl}/send-mail`;
  const result = {
   // type: ACTION_TYPES.UPDATE_DEMANDE,
    payload: axios.post(requestUrl, entity),
    meta: {
      errorMessage: "Les données renseignées ne sont pas correctes",
      successMessage: "Données renseignées correctes"
    }
  };
  //dispatch(getEntities());
  return result;
};

export const downLoadResource = (typeActe: string, numero: string, loadFunction?: Function, errorFunctrion?: Function) => {
  loadFunction(true);
  axios.get(`api/download/${typeActe}/${numero}`, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.rel = 'noopener'
      link.download = `${typeActe}-${numero}.pdf`;
      document.body.appendChild(link);
      setTimeout(() => { URL.revokeObjectURL(link.href) }, 4E4) // 40s
      setTimeout(() => { link.click(); }, 0)
      loadFunction(false);
    })
    .catch(error => {
      errorFunctrion("Une erreur s'est produite lors de l'operation!");
      loadFunction(false)
    });
};