import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './views/shared/error/error-boundary';
import PageAvertissement from './views/login/login2';
import { useState } from "react";
import 'react-phone-number-input/style.css';

const loading = (
  <div className="pt-3 text-center loading-pos">
    <div className="spinner-grow text-dark" style={{ width: '10rem', height: '10rem' }} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const LoginModal = React.lazy(() => import('./views/login/login2_'));

// Pages
const Login = React.lazy(() => import('./views/login/login'));
const Logout = React.lazy(() => import('./views/login/logout'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

//export interface IAppProps extends StateProps, DispatchProps {}

const App = (props) => {

  const [isRegistered, setIsRegistered] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [registeredUsers, setRegisteredUsers] = useState([]);

  const handleRegistration = (success) => {
    setIsRegistered(success);
  };

  const handleLogin = (success) => {
    setIsLoggedIn(success);
  };

  return (
    <HashRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ErrorBoundary>
        <React.Suspense fallback={loading}>
          <Switch>
            {/* <Route  path="/login" render={prop => <Login {...prop} />} />
           <Route exact path="/logout" render={prop => <Logout {...prop} />} /> */}
            {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} /> */}
            <Route path="/" render={prop =>
              <ErrorBoundary>
                  <TheLayout/> 
                  {/* <LoginModal/>    */}
              </ErrorBoundary>} />
          </Switch>
        </React.Suspense>
      </ErrorBoundary>
    </HashRouter>
  );
};

/* const mapStateToProps = ({ authentication }: IRootState) => ({
  userAuthorities: authentication.account.roles
});

const mapDispatchToProps = { getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(App); */

export default App;
