import { IParametre } from "./parametre.model";

export interface IValeur {
    id?: any;
    libelle?: string;
    parametre?: IParametre;
    statut?: boolean;
    deleted?: boolean;
}

export const defaultValue: Readonly<IValeur> = {
    deleted: false
}