import { IInfraction } from "../administration/infraction.model";
import { IValeur } from "../administration/valeur.model";
import { IDecisionInfraction } from "../administration/decision_infraction.model";
import { ILigneCasier } from "../certificat-nationalite-service/ligne-casier.model";
import { IDemande } from "../certificat-nationalite-service/demande.model";
import { string } from "prop-types";

export interface IBullettin {
    id?:any;
    demande?:IDemande;
    ligneCasier?:ILigneCasier[]
    ligneCasiers?:string
    expired?:boolean
    
}

export const defaultValue: Readonly<IBullettin> = {
   
}