export interface IUser {
  id?: any;
  login?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  activated?: boolean;
  hasBeenConnected?: boolean;
  langKey?: string;
  telephone?: string;
  roles?: string[];
  structureCode?: string;
  createdBy?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null;
  password?: string;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  email: null,
  firstName: '',
  lastName: '',
  telephone: null,
  hasBeenConnected: false,
  activated: true,
  langKey: '',
  roles: [],
  structureCode: null,
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
};
