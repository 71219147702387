import { REQUEST, FAILURE, SUCCESS } from "../../../shared/reducers/action-type.util";
import axios from "axios";
import { ICrudGetAllAction, ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from "../../../shared/reducers/redux-action.type";
import { ILocalite,  defaultValue } from "../../../shared/model/certificat-nationalite-service/localite.model";
import { API_SERVICE_URL } from "../../../config/api-service-url";
import { cleanEntity } from "src/views/utils/entity-utils";




export const ACTION_TYPES = {
  FETCH_LOCALITES_LIST: 'localites/FETCH_LOCALITES_LIST',
  FETCH_LOCALITES: 'localites/FETCH_LOCALITES',
  CREATE_LOCALITES: 'moyen-localites/CREATE_LOCALITES',
  UPDATE_LOCALITES: 'localites/UPDATE_LOCALITES',
  DELETE_LOCALITES: 'localites/DELETE_LOCALITES',
  CHANGE_STATE: 'localites/CHANGE_STATE',
  RESET: 'localites/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ILocalite>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  data: {}
};

export type LocaliteState = Readonly<typeof initialState>;

// Reducer

export default (state: LocaliteState = initialState, action: any): LocaliteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LOCALITES_LIST):
    case REQUEST(ACTION_TYPES.FETCH_LOCALITES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_LOCALITES):
    case REQUEST(ACTION_TYPES.CREATE_LOCALITES):
    case REQUEST(ACTION_TYPES.DELETE_LOCALITES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_LOCALITES_LIST):
    case FAILURE(ACTION_TYPES.FETCH_LOCALITES):
    case FAILURE(ACTION_TYPES.CREATE_LOCALITES):
    case FAILURE(ACTION_TYPES.UPDATE_LOCALITES):
    case FAILURE(ACTION_TYPES.DELETE_LOCALITES):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
       errorMessage: action.meta.errorMessage
      };
    case SUCCESS(ACTION_TYPES.FETCH_LOCALITES_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_LOCALITES):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_LOCALITES):
    case SUCCESS(ACTION_TYPES.UPDATE_LOCALITES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_LOCALITES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.CHANGE_STATE:
      return {
        ...state,
        updateSuccess: false
      };
    default:
      return state;
  }
};


const apiUrl = `${API_SERVICE_URL.EMPTY}api/demandes`;



// Actions

export const getLocalites = () => {
  const requestUrl = `${apiUrl}/localites`;
  return {
    type: ACTION_TYPES.FETCH_LOCALITES_LIST,
    payload: axios.get(requestUrl)
  };
};





export const getEntity: ICrudGetAction<ILocalite> = name => {
  const requestUrl = `${apiUrl}/${name}`;
  return {
    type: ACTION_TYPES.FETCH_LOCALITES,
    payload: axios.get<ILocalite>(requestUrl)
  };
};




export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const change = () => ({
  type: ACTION_TYPES.CHANGE_STATE
});

