import { combineReducers } from 'redux';
import structure, { StructureState } from 'src/views/administration/structure/structure.reducer';
import parametre, { ParametreState } from 'src/views/administration/parametre/parametre.reducer';
import valeur, { ValeurState } from 'src/views/administration/valeur/valeur.reducer';
import userManagement, { UserManagementState } from 'src/views/administration/user-management/user-management.reducer';
import authentication, { AuthenticationState } from './authentication';
import appState, { AppState } from './app.reducer';
import fileReader, { FileReaderState } from 'src/views/shared/layout/file-reader/file-reader.reducer';
import profile, { ApplicationProfileState } from './application-profile';
import demande, { DemandeState } from 'src/views/service/certificat-nationalite-service/demande/demande.reducer';
//import decision, { DecisionState } from 'src/views/service/certificat-nationalite-service/demande/decision.reducer';
import bullettin, { BullettinState } from 'src/views/service/certificat-nationalite-service/demande/bullettin.reducer';
import document, { DocumentState } from 'src/views/service/certificat-nationalite-service/demande/document.reducer';
import demandeur, { DemandeurState } from 'src/views/service/certificat-nationalite-service/demandeur/demandeur.reducer';
import paiement, { PaiementState } from 'src/views/service/certificat-nationalite-service/demande/paiement.reducer';
import moyenPaiement, { MoyenPaiementState } from 'src/views/service/certificat-nationalite-service/demande/moyen-paiement.reducer';
import localite, { LocaliteState } from 'src/views/service/certificat-nationalite-service/demande/localites.reducer';
import juridiction, { JuridictionState } from 'src/views/service/certificat-nationalite-service/demande/juridiction.reducer';
import typePieceModeObtention, { TpmdState } from 'src/views/service/certificat-nationalite-service/demande/type-piece-mode-obtention.reducer';


export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly userManagement: UserManagementState;
  readonly structure: StructureState;
  readonly parametre: ParametreState;
  readonly valeur: ValeurState;
  readonly appState: AppState;
  readonly fileReader: FileReaderState;
  readonly profile: ApplicationProfileState;
  readonly demande: DemandeState;
  readonly bullettin: BullettinState;
  readonly document: DocumentState;
  readonly demandeur: DemandeurState;
  readonly paiement: PaiementState;
  readonly moyenPaiement: MoyenPaiementState;
  readonly localite: LocaliteState;
  readonly juridiction: JuridictionState;
  readonly typePieceModeObtention: TpmdState;
};

const rootReducer = combineReducers<IRootState>({
  authentication,
  userManagement,
  structure,
  parametre,
  valeur,
  appState,
  fileReader,
  profile,
  demande,
  bullettin,
  document,
  demandeur,
  paiement,
  moyenPaiement,
  localite,
  juridiction,
  typePieceModeObtention
});

export default rootReducer;
