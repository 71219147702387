import { REQUEST, FAILURE, SUCCESS } from "../../../shared/reducers/action-type.util";
import axios from "axios";
import { ICrudGetAllAction, ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from "../../../shared/reducers/redux-action.type";
import { IPaiement, defaultValuePaiement } from "../../../shared/model/certificat-nationalite-service/paiement.model";

import { API_SERVICE_URL } from "../../../config/api-service-url";
import { cleanEntity } from "src/views/utils/entity-utils";
import { IDemande, defaultValue } from "../../../shared/model/certificat-nationalite-service/demande.model";




export const ACTION_TYPES = {
  FETCH_PAIEMENT_LIST: 'paiement/FETCH_PAIEMENT_LIST',
  FETCH_PAIEMENT: 'paiement/FETCH_PAIEMENT',
  CREATE_PAIEMENT: 'paiement/CREATE_PAIEMENT',
  UPDATE_PAIEMENT: 'paiement/UPDATE_PAIEMENT',
  DELETE_PAIEMENT: 'paiement/DELETE_PAIEMENT',
  CHANGE_STATE: 'paiement/CHANGE_STATE',
  RESET: 'paiement/RESET',
  VERIFY_PAIEMENT: 'paiement/VERIFY_PAIEMENT',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPaiement>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  data: {}
};

export type PaiementState = Readonly<typeof initialState>;

// Reducer

export default (state: PaiementState = initialState, action: any): PaiementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAIEMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PAIEMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_PAIEMENT):
    case REQUEST(ACTION_TYPES.CREATE_PAIEMENT):
    case REQUEST(ACTION_TYPES.VERIFY_PAIEMENT):
    case REQUEST(ACTION_TYPES.DELETE_PAIEMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PAIEMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PAIEMENT):
    case FAILURE(ACTION_TYPES.CREATE_PAIEMENT):
    case FAILURE(ACTION_TYPES.UPDATE_PAIEMENT):
    case FAILURE(ACTION_TYPES.VERIFY_PAIEMENT):
    case FAILURE(ACTION_TYPES.DELETE_PAIEMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
       errorMessage: action.payload.error
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAIEMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAIEMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_PAIEMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_PAIEMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.VERIFY_PAIEMENT):
        return {
          ...state,
          //entity: action.payload.data
        };
    case SUCCESS(ACTION_TYPES.DELETE_PAIEMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.CHANGE_STATE:
      return {
        ...state,
        updateSuccess: false
      };
    default:
      return state;
  }
};


const apiUrl = `${API_SERVICE_URL.EMPTY}api/demandes`;
const apiLdc = `https://app.ligdicash.com/pay/v01/redirect/checkout-invoice/create`;
const apiVerify = `https://app.ligdicash.com/pay/v01/redirect/checkout-invoice/confirm/?invoiceToken=`

const urlCM = "https://testbed.corismoney.com/external/v1/api/send-code-otp"
const urlCMGet = "https://testbed.corismoney.com/external/v1/api/hash256?originalString=hjhjfdtujjhg"


// Actions

export const createEntityToGetCMOTP = (entity) =>  {
  const resquestUrl = `${apiUrl}/paiement/getotpCM`;
  
  const result = ({
    type: ACTION_TYPES.CREATE_PAIEMENT,
    payload: axios.post(resquestUrl, cleanEntity(entity))
  });
  return result;
};

export const validerPaimentCM: ICrudPutAction<IPaiement> = (entity) => async dispatch => {
  const resquestUrl = `${apiUrl}/paiement/validerpaiementCM`;
  
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PAIEMENT,
    payload: axios.post(resquestUrl, cleanEntity(entity))
  });
  return result;
};

export const createEntityToGetMoovMoneyOTP = (entity) =>  {
  const resquestUrl = `${apiUrl}/paiement/getOtpMM`;
  
  const result = ({
    type: ACTION_TYPES.CREATE_PAIEMENT,
    payload: axios.post(resquestUrl, cleanEntity(entity))
  });
  return result;
};

export const createEntityToResendMoovMoneyOTP = (entity) =>  {
  const resquestUrl = `${apiUrl}/paiement/resendOtpMM`;
  
  const result = ({
    type: ACTION_TYPES.CREATE_PAIEMENT,
    payload: axios.post(resquestUrl, cleanEntity(entity))
  });
  return result;
};


export const validerPaimentMoovMoney = (entity) => {
  const requestUrl = `${apiUrl}/paiement/validerPaiementMM`;
  return{
    payload: axios.post(requestUrl,cleanEntity(entity))
  }
};

/* export const createEntityToGetOTP= (entity,demande) => {
  const requestUrl = `${apiUrl}/paiement/getotp`;

  let clientId = "MINISTEREDELAJUSTICE";
  let codeSecrete = "$2a$10$R.PSUV1QOoditZxwVBXclO9bIKjvsgf1d/J7d4qVFT7blBfxrDNGu";
  const hashParam = SHA256(entity.codePays+entity.numT+codeSecrete).toString()
  
  const result = {
    //type: ACTION_TYPES.CREATE_PAIEMENT,
    payload:  axios.post(urlCM,entity
     ,{
        headers: {
          mode:"no-cors",
          "access-control-allow-origin": "http://localhost:5000/",
          Accept: 'multipart/form-data',
          hashParam: hashParam,
          clientId:clientId,
          //Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9hcHAiOiI4NTYwIiwiaWRfYWJvbm5lIjoyODQzNzksImRhdGVjcmVhdGlvbl9hcHAiOiIyMDIzLTAzLTE2IDEzOjI5OjE3In0.YkgNQSj5ncRewPtzPyG027fhEoHRYMaE4Cn05Qc8qj0'
        },
      withCredentials:true,

      }).then(response => {
       // window.location.href = response.data.response_text
      })  
  };
  return result;
}; */

export const validerPaiementGIP: ICrudPutAction<IPaiement> = (entity) => async dispatch => {
  const resquestUrl = `${apiUrl}/paiement/ginfopoint`;
  
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PAIEMENT,
    payload: axios.post(resquestUrl, cleanEntity(entity))
  });
  return result;
};

export const createLgcEntity =(entity,demande) => {
  const requestUrl = `${apiUrl}/paiement/LDC`;

  const result = ({
   
    payload: axios.post(requestUrl,cleanEntity(entity)/*,{
      headers: {
        Accept: 'application/json',
        Apikey: '5YL53P97BX327WNED',
        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9hcHAiOiI4NTYwIiwiaWRfYWJvbm5lIjoyODQzNzksImRhdGVjcmVhdGlvbl9hcHAiOiIyMDIzLTAzLTE2IDEzOjI5OjE3In0.YkgNQSj5ncRewPtzPyG027fhEoHRYMaE4Cn05Qc8qj0'
      },
    }*/).then(response => {
      if(response.data){
        window.location.href = response.data
      }else{
        return response
      }
      
    })
  });
  
  return result;
};


export const verifyStatutLgc:  ICrudPutAction<IPaiement> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/paiement/LDC/`;
  let list ={}
  const result = await dispatch({
    payload: axios.get(requestUrl+entity/*,{
      headers: {
        //invoiceToken: entity,
        Apikey: '5YL53P97BX327WNED',
        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9hcHAiOiI4NTYwIiwiaWRfYWJvbm5lIjoyODQzNzksImRhdGVjcmVhdGlvbl9hcHAiOiIyMDIzLTAzLTE2IDEzOjI5OjE3In0.YkgNQSj5ncRewPtzPyG027fhEoHRYMaE4Cn05Qc8qj0'
      },
    }*/).then(response => {
      //window.location.href = response.data.response_text
      list = response.data
      return response.data
      
    })
  });
  return result;
};



export const validerPaiementOM =(entity) => {
  const requestUrl = `${apiUrl}/paiement/orange_money`;

  return{
   
    payload: axios.post(requestUrl,cleanEntity(entity))
  }
  
};


export const getEntity: ICrudGetAction<IPaiement> = name => {
  const requestUrl = `${apiUrl}/${name}`;
  return {
    type: ACTION_TYPES.FETCH_PAIEMENT,
    payload: axios.get<IPaiement>(requestUrl)
  };
};

export const getEntityEnLigne: ICrudGetAction<IPaiement> = name => {
  const requestUrl = `${apiUrl}/en-ligne/${name}`;
  return {
    type: ACTION_TYPES.FETCH_PAIEMENT,
    payload: axios.get<IPaiement>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPaiement> = (entity) => async dispatch => {
  const resquestUrl = `${apiUrl}/save/paiement`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PAIEMENT,
    payload: axios.post(resquestUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IPaiement> = entity => {
  const requestUrl = `${apiUrl}/${entity.id}`;
  const result = {
    type: ACTION_TYPES.UPDATE_PAIEMENT,
    payload: axios.patch(requestUrl, entity)
  };
  //dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPaiement> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PAIEMENT,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const change = () => ({
  type: ACTION_TYPES.CHANGE_STATE
});

