import axios from 'axios';
import { defaultValue, ILoadDocument } from '../../../shared/model/file-service/load-document.model';

import { REQUEST, SUCCESS, FAILURE } from '../../../shared/reducers/action-type.util';

export const ACTION_TYPES = {
  GET_FILE: 'file/GET_FILE',
  RESET: 'file/RESET',
};

const initialState = {
  loading: false,
  entity: defaultValue,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
  totalItems: 0
};

export type FileReaderState = Readonly<typeof initialState>;

// Reducer
export default (state: FileReaderState = initialState, action): FileReaderState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_FILE):
      return {
        ...initialState,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_FILE):
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        updateFailure: true,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.GET_FILE):
      return {
        ...initialState,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
const apiUrl = `/api/resources`;

export const getFileEntity = entity => ({
  type: ACTION_TYPES.GET_FILE,
  payload: axios.post(`${apiUrl}/read`, entity),
});

const apiUrl2 = `api/demandes/resources`;
export const getFileEntity2 = entity => ({
  type: ACTION_TYPES.GET_FILE,
  payload: axios.post(`${apiUrl2}`,entity),
});

export const getFileEntity3 = (dossier, filename) => ({
  type: ACTION_TYPES.GET_FILE,
  payload: axios.get(`${apiUrl2}/${dossier}/${filename}`),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
