export const ACTION_TYPES = {
    SET: 'SET'
  };

  const initialState = {
    sidebarShow: "responsive",
  };

  export type AppState = Readonly<typeof initialState>;

  export default (state: AppState = initialState, { type, ...rest }) : AppState => {
    switch (type) {
      case ACTION_TYPES.SET:
        return { ...state, ...rest };
      default:
        return state;
    }
  };

  export const setResponsiveSidebar:(val: any) => void = val => dispatch => {
    dispatch({type: ACTION_TYPES.SET, sidebarShow: val});
  }

  export const setResponsiveSidebarMobile :(val: any) => void = val => dispatch =>{
    dispatch({type: ACTION_TYPES.SET, sidebarShow: val});
  }