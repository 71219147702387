import { REQUEST, FAILURE, SUCCESS } from "../../shared/reducers/action-type.util";
import axios from "axios";
import { ICrudGetAllAction, ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from "../../shared/reducers/redux-action.type";
import { IValeur, defaultValue } from "../../shared/model/administration/valeur.model";

export const ACTION_TYPES = {
  FETCH_VALEUR_LIST: 'valeur/FETCH_VALEUR_LIST',
  FETCH_VALEUR: 'valeur/FETCH_VALEUR',
  CREATE_VALEUR: 'valeur/CREATE_VALEUR',
  UPDATE_VALEUR: 'valeur/UPDATE_VALEUR',
  DELETE_VALEUR: 'valeur/DELETE_VALEUR',
  RESET: 'valeur/RESET'
};

  const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IValeur>,
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false
  };

  export type ValeurState = Readonly<typeof initialState>;

// Reducer

export default (state: ValeurState = initialState, action: any): ValeurState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VALEUR_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VALEUR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_VALEUR):
    case REQUEST(ACTION_TYPES.UPDATE_VALEUR):
    case REQUEST(ACTION_TYPES.DELETE_VALEUR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_VALEUR_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VALEUR):
    case FAILURE(ACTION_TYPES.CREATE_VALEUR):
    case FAILURE(ACTION_TYPES.UPDATE_VALEUR):
    case FAILURE(ACTION_TYPES.DELETE_VALEUR):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_VALEUR_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_VALEUR):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_VALEUR):
    case SUCCESS(ACTION_TYPES.UPDATE_VALEUR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_VALEUR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};


const apiUrl = `api/parametre_values`;

// Actions

export const getEntities: ICrudGetAllAction<IValeur> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_VALEUR_LIST,
    payload: axios.get(requestUrl)
  };
};

export const getList: ICrudGetAllAction<IValeur> = () => {
  const requestUrl = `${apiUrl}`;
  return {
    type: ACTION_TYPES.FETCH_VALEUR_LIST,
    payload: axios.get(requestUrl)
  };
};

export const getParameterValues = libelle => {
  const requestUrl = `${apiUrl}/${libelle}/list`;
  return {
    type: ACTION_TYPES.FETCH_VALEUR_LIST,
    payload: axios.get(requestUrl)
  };
};

export const getParameterValuesFrom = (parameters) => {
  const requestUrl = `${apiUrl}/parameters`;
  return {
    type: ACTION_TYPES.FETCH_VALEUR_LIST,
    payload: axios.post(requestUrl, parameters)
  };
};

export const getEntity: ICrudGetAction<IValeur> = libelle => {
  const requestUrl = `${apiUrl}/${libelle}`;
  return {
    type: ACTION_TYPES.FETCH_VALEUR,
    payload: axios.get<IValeur>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IValeur> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VALEUR,
    payload: axios.post(apiUrl, entity)
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IValeur> = entity => async dispatch =>  {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VALEUR,
    payload: axios.put(apiUrl, entity)
  });
 dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IValeur> = libelle => async dispatch => {
  const requestUrl = `${apiUrl}/${libelle}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VALEUR,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});