export interface IStructure {
    id?: any;
    nom?: string;
    code?: string;
    actif?: boolean;
    deleted?: boolean;
}

export const defaultValue: Readonly<IStructure> = {
    actif: true,
    deleted: false
}