import { IValeur } from "../administration/valeur.model";
import { IDemande } from "../certificat-nationalite-service/demande.model";

export interface IDocument {
    id?: any;
    demande?: IDemande;
    typePiece?: IValeur;
    typeActeNaissance?:IValeur;
    url?: string;
    libelle?: string;
    referencePiece?: string;
    dateEtablissementPiece?: Date;
    lieuEtablissementPiece?: string;
    autoriteDelivrancePiece?: IValeur;
}

export const defaultValue: Readonly<IDocument> = {
}