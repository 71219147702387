import { REQUEST, FAILURE, SUCCESS } from "../../../shared/reducers/action-type.util";
import axios from "axios";
import { ICrudGetAllAction, ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from "../../../shared/reducers/redux-action.type";
import { ITypePieceModeObtention,  defaultValue } from "../../../shared/model/certificat-nationalite-service/type_piece_mode_obtention.model";
import { API_SERVICE_URL } from "../../../config/api-service-url";
import { cleanEntity } from "src/views/utils/entity-utils";




export const ACTION_TYPES = {
  FETCH_TPMDS_LIST: 'tpmds/FETCH_TPMDS_LIST',
  FETCH_TPMDS: 'tpmds/FETCH_TPMDS',
  CREATE_TPMDS: 'tpmds/CREATE_TPMDS',
  UPDATE_TPMDS: 'tpmds/UPDATE_TPMDS',
  DELETE_TPMDS: 'tpmds/DELETE_TPMDS',
  CHANGE_STATE: 'tpmds/CHANGE_STATE',
  RESET: 'tpmds/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITypePieceModeObtention>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  data: {}
};

export type TpmdState = Readonly<typeof initialState>;

// Reducer

export default (state: TpmdState = initialState, action: any): TpmdState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TPMDS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TPMDS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_TPMDS):
    case REQUEST(ACTION_TYPES.CREATE_TPMDS):
    case REQUEST(ACTION_TYPES.DELETE_TPMDS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_TPMDS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TPMDS):
    case FAILURE(ACTION_TYPES.CREATE_TPMDS):
    case FAILURE(ACTION_TYPES.UPDATE_TPMDS):
    case FAILURE(ACTION_TYPES.DELETE_TPMDS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
       errorMessage: action.meta.errorMessage
      };
    case SUCCESS(ACTION_TYPES.FETCH_TPMDS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_TPMDS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_TPMDS):
    case SUCCESS(ACTION_TYPES.UPDATE_TPMDS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_TPMDS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.CHANGE_STATE:
      return {
        ...state,
        updateSuccess: false
      };
    default:
      return state;
  }
};


const apiUrl = `${API_SERVICE_URL.EMPTY}api/demandes`;



// Actions

export const getTpmds = () => {
  const requestUrl = `${apiUrl}/type_piece_mode_obtention`;
  return {
    type: ACTION_TYPES.FETCH_TPMDS_LIST,
    payload: axios.get(requestUrl)
  };
};









export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const change = () => ({
  type: ACTION_TYPES.CHANGE_STATE
});

